//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

$font-prefix: '/fonts/ibm-plex';
@import '../../resources/static/fonts/ibm-plex/scss/ibm-plex.scss';

html {
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}

body {
	font-size: 1.3rem;
	line-height: 1.66;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
h7,
.h7 {
	font-family: "IBM Plex Sans Condensed";
	font-weight: 700;

	a {
		&.anchor {
			width: 0;
			height: 0;
			visibility: hidden;
		}

		&.link-to-headline {
			display: none;

			svg {
				* {
					fill: #b2b2b2;
				}
			}
		}
	}

	&:hover {
		a {
			&.link-to-headline {
				display: inline;
			}
		}
	}
}

h4,
.h4 {
	font-size: 1.3rem;
}

code {
	word-break: normal;
}

code,
pre,
code[class*="language-"],
pre[class*="language-"] {
	font-family: $font-family-monospace;
}

pre,
pre[class*="language-"],
code[class*="language-"] {
	// same as .bg-subshell-gold-alt
	background: mix(theme-color('subshell-gold'), $white, 6%);
}

pre,
pre[class*="language-"] {
	font-size: 0.9rem;
	max-height: 30rem;
	border-radius: .3125rem;

	// reset Leap's padding to what Prism uses because it interferes with line highlighting
	padding: 1rem;
}

p,
div,
li,
td {
	> code {
		color: #dd4a68;
	}
}

table {
	&.table-copytext {
		font-size: 0.9rem;
	}
}

.doc-search-results {
	p,
	table {
		font-size: 1rem;
	}

	table {
		tr {
			&:first-child {
				th,
				td {
					border-top: 0;
				}
			}
		}
	}
}

.divider {
	&:first-child {
		// remove useless bottom border so that dividers can be used in standalone sections
		bottom: 0;
	}
}

// SVG icons should behave like menu item text in navbar.
.navbar-nav {
	.nav-link {
		svg {
			&.icon {
				* {
					fill: rgba(255, 255, 255, 0.5);
					transition: fill 0.2s ease;
				}
			}
		}

		&:hover {
			svg {
				&.icon {
					* {
						fill: rgba(255, 255, 255, 0.9);
					}
				}
			}
		}

		&.active {
			svg {
				&.icon {
					* {
						fill: #fff;
					}
				}
			}
		}
	}
}

.lead {
	font-weight: 500;
	font-size: 1.6rem;
	margin-bottom: -0.25rem;
}

.article {
	p {
		&:first-child {
			// First paragraph should always have top padding even if it's not an intro text.
			margin-top: 0rem;
		}

		&.lead {
			font-size: 1.3rem;
			font-weight: 400;

			&:first-child {
				// First paragraph should always have top padding even if it's not an intro text.
				margin-top: 0rem;

				&::first-letter {
					margin-top: 0;
					color: #bcb563;
					font-weight: bold;
					float: left;
					margin-top: 0.08em;
					margin-right: 0.1em;
					font-family: "IBM Plex Serif";
					font-size: 5em;
					line-height: 0.7em;
				}
			}
		}
	}

	// use same settings for <ul> and <ol>
	ul,
	ol {
		&:not(.flickity-page-dots) {
			> li {
				&:not(:last-child) {
					margin-bottom: 0.75rem;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	> h1,
	> .h1,
	> h2,
	> .h2,
	> h3,
	> .h3,
	> h4,
	> .h4,
	> h5,
	> .h5,
	> h6,
	> .h6,
	> h7,
	> .h7 {
		margin-top: 2.5rem;
	}

	div {
		&[data-copytext-section] {
			> h1,
			> .h1,
			> h2,
			> .h2,
			> h3,
			> .h3,
			> h4,
			> .h4,
			> h5,
			> .h5,
			> h6,
			> .h6,
			> h7,
			> .h7 {
				margin-top: 2.5rem;
			}
		}
	}

	table {
		td {
			&:not(:last-child) {
				// wtf Leap disables all word wrapping, why?
				white-space: inherit;
			}

			// use same settings for <ul> and <ol>
			ul,
			ol {
				&:not(.flickity-page-dots) {
					> li {
						&:not(:last-child),
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.avatar {
	&.avatar-icon {
		// allows an avatar image to be used as an icon
		// (same width and height as SVG icons)
		width: 24px;
		height: 24px;
	}
}

.hover-arrow {
	&:after {
		// Leap's original arrow does not work with our font
		content: "→";
	}
}

.tab-content {
	> .tab-pane {
		> figure {
			figcaption {
				// make sure image caption stands out better when used in a tab panel
				font-size: inherit;
				color: inherit;
			}
		}
	}
}

figcaption {
	// image captions should always be centered
	text-align: center;
}

// navbar always gets background color when it is stuck at the top
.navbar-container {
	nav {
		transition: all $transition-time ease;
	}

	&.stuck {
		nav {
			background-color: theme-color('primary-3');
		}
	}
}

.navbar-brand {
	margin-left: -15px;

	// don't need these since navbar contents has padding already
	padding-top: 0;
	padding-bottom: 0;

	> * {
		// subshell logo aspect ratio and size
		width: 95px;
		height: 30px;
	}
}

footer {
	.subshell-logo {
		// subshell logo aspect ratio and size
		width: 71px;
		height: 55px;
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	svg {
		&.icon-heart {
			height: 1rem;
			margin-bottom: 3px;

			* {
				fill: red !important;
			}
		}

		&.icon-hamburg {
			* {
				fill: #090b0c !important;
			}
		}
	}
}

// border for left side sticky navigation
.side-nav-left {
	border-right: 1px solid #ccc;
	@include font-size($font-size-base * 0.75);
}

.card {
	.card-body {
		@include font-size($font-size-base * 0.833);
		line-height: $line-height-base * 0.9;

		h4 {
			@include font-size($h4-font-size * 0.866);
		}
	}

	// try to cram more of these cards into the page
	&.documentation-card {
		.card-body {
			padding: 1rem;

			h4 {
				margin-bottom: 0.3rem;
			}

			p {
				margin-bottom: 0;
			}
		}
	}
}

.text-light {
	// for elements in a .card that is inside a .text-light, reset their colors to defaults because card background is white
	.card {
		> * {
			color: $body-color;
		}

		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6,
		h7,
		.h7 {
			color: $headings-color;
		}

		a {
			color: theme-color('primary');
		}
	}
}

.container-fluid {
	// in a fluid container, prevent Flickity's previous/next buttons from leaking out of the container
	.image-gallery {
		padding-left: 4.5rem;
		padding-right: 4.5rem;
	}

	// disable our padding when Flickity's previous/next buttons are hidden
	@media (max-width: 1199.98px) {
		.image-gallery {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.navbar {
	.navbar-nav {
		.nav-item {
			a {
				padding-bottom: 0;
			}
		}
	}

	&.navbar-dark {
		.form-control {
			&.form-control-dark {
				background-color: transparent;
				color: rgba(255, 255, 255, 0.75);
			}
		}
	}
}

input {
	&::placeholder {
		font-style: italic;
	}
}

form {
	&.doc-search {
		> .input-group {
			> .input-group-prepend {
				> select {
					background-color: #ddd;
				}
			}
		}
	}
}

.issue-log {
	.issue-badge {
		border-radius: 0.2rem;
		display: inline;
		flex: 0 0 6.5rem;
		margin-right: 1rem;
		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
		text-transform: uppercase;
		font-family: $font-family-monospace;
		text-align: center;

		&.issue-changed {
			border: 2px $warning solid;
			color: $warning;

			&.issue-important {
				background-color: $warning;
				color: #eee;
			}
		}

		&.issue-added,
		&.issue-improved {
			border: 2px $success solid;
			color: $success;

			&.issue-important {
				background-color: $success;
				color: #eee;
			}
		}

		&.issue-fixed {
			border: 2px $info solid;
			color: $info;

			&.issue-important {
				background-color: $info;
				color: #eee;
			}
		}
	}
}

.flex-items-start {
	align-items: flex-start !important;
}

button {
	&.badge {
		border: 0;
		outline: 0;
	}
}

.badge {
	// reset Leap's fancy letter spacing
	letter-spacing: inherit;

	&.badge-ui-change,
	&.badge-issue-notes,
	&.badge-issue-component {
		font-family: $font-family-monospace;
		background-color: $gray-400;
	}

	&.badge-ui-change,
	&.badge-issue-notes {
		// should really be 1rem to correspond with .issue-log.issue-badge,
		// but somehow that looks bigger than it should
		margin-right: 0.75rem;
	}
}

input {
	&.col-form-check-input {
		// make sure checkbox sits correctly
		padding-top: calc(.4375rem + 1px);
    	padding-bottom: calc(.4375rem + 1px);
	}
}

blockquote {
	font-style: italic;

	&.tweet {
		padding: 2rem;
	}
}

.quotation-text {
	font-weight: 400;
	font-size: 1.3rem;
	line-height: 1.2;
}

.typed-cursor {
	font-weight: 200;
}

.typed-plain {
	font-weight: 300;
}

.text-muted {
	a {
		color: #6a7cbb;
	}
}

.breadcrumb {
	// reset Leap's fancy styling
	padding: 0;
	border-radius: 0;
	background-color: inherit;
}

.breadcrumb-item {
	&.active {
		// bold instead of Leap's text color
		color: inherit;
		font-weight: bold;
	}
}

.display-1,
.display-2,
.display-3,
.display-4,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
h7,
.h7,
.btn {
	// reset Leap's letter spacing since we're using a condensed font already
	letter-spacing: inherit;
}

.plyr__video-wrapper,
.plyr__poster,
.plyr--video {
	// restore correct preview image position
	background: center;
}

dl {
	&.tabular {
		font-size: 1rem;

		dt,
		dd {
			padding-top: 0.5rem;
		}

		dd {
			padding-bottom: 1rem;

			&:not(:last-of-type) {
				border-bottom: 1px solid $gray-300;
			}
		}

		@include media-breakpoint-up(md) {
			dt {
				padding-bottom: 0.5rem;

				&:not(:last-of-type) {
					border-bottom: 1px solid $gray-300;
				}
			}

			dd {
				padding-bottom: 0.5rem;
			}
		}
	}
}

.row {
	dd {
		// unset default margin bottom when inside .row
		margin-bottom: 0;
	}
}

nav {
	&.language-nav {
		font-size: .875rem;
	}
}

.related-content {
	.related-content-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	&:not(:last-child) {
		.related-content-text {
			border-bottom: 1px solid $gray-300;
		}
	}
}

h1 {
	.headline-logo {
		height: 4rem;
		vertical-align: bottom;
	}

	svg {
		&.headline-logo {
			* {
				fill: #fff !important;
			}
		}
	}
}

svg {
	&.side-decoration {
		width: 10rem;
		height: 10rem;
	}
}

.z-index-fixed {
	z-index: $zindex-fixed;
}

.sub-headline {
	font-size: 1.25rem;
	font-weight: 400;

	.event-date {
		font-weight: bold;
	}
}

label {
	&.required {
		&:after {
			content: " *";
		}
	}
}

@include media-breakpoint-down(md) {
	.breadcrumb-item {
		max-width: 750px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@include media-breakpoint-down(sm) {
	.breadcrumb-item {
		max-width: 400px;
	}
}

@include media-breakpoint-down(xs) {
	.breadcrumb-item {
		max-width: 300px;
	}
}

.main-image-border {
	border: 1px solid #dee2e6;
}

.process-vertical {
	li {
		&:not(:last-child) {
			&:after {
				background: transparent;
				width: 0;
			}
		}

		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6,
		h7,
		.h7 {
			font-family: $font-family-sans-serif;
			font-weight: 400;
			font-size: 1.3rem;
		}
	}
}

.form-small {
	.form-control {
		@include font-size($font-size-base * 0.75);
	}
}

.image-half {
	width: 460px;
}

@each $bp in (xs, sm, md, lg, xl) {
	@include media-breakpoint-up(#{$bp}) {
		@each $prop, $abbrev in (width: w, height: h) {
			@each $size, $length in $sizes {
				.#{$abbrev}-#{$bp}-#{$size} { #{$prop}: $length !important; }
			}
		}
	}
}

// fix dropdown menu items getting white color in Leap
.navbar {
	.dropdown-menu {
		.dropdown-item {
			color: $dropdown-link-color !important;

			&.active,
			&:active {
				color: $dropdown-link-active-color !important;
			}
		}
	}
}

// disable background color in dropdown menus when they're being displayed inline
@include media-breakpoint-down(md) {
	.navbar-dark {
		.dropdown-menu {
			background-color: theme-color('primary-3');
			border: 0;
			box-shadow: none !important;

			.dropdown-item {
				color: $navbar-dark-color !important;

				&.active,
				&:active {
					color: $white !important;
				}
			}
		}
	}
}

.feature-list {
	h5,
	p {
		font-size: 1rem;
		line-height: 1.5;
	}
}

img {
	&.responsive-image {
		height: auto;
	}
}

picture {
	&.softwareApplicationDocsTeaser {
		max-width: 4.5rem;
	}
}

.input-group {
	.icon {
		* {
			// reset Leap's rubbish
			fill: inherit !important;
		}
	}
}

.text-light {
	svg {
		&.icon {
			&:not([class*=bg-]) {
				* {
					// default in Leap, but we need !important here now
					fill: #fff !important;
				}
			}
		}
	}
}

.dropdown-toggle {
	&.btn {
		> .icon {
			&:last-child {
				// reset Leap's rubbish
				transform: none;
			}
		}
	}
}

.doc-search-results {
	a {
		color: $blue;

		&:visited,
		&:hover {
			color: $blue;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

.mark,
mark {
	background-color: theme-color('subshell-gold-doc-search-mark');
	padding-left: 0.15rem;
	padding-right: 0.15rem;
	padding-top: 0;
	padding-bottom: 0;
	font-weight: bolder;
}

.mantine-SegmentedControl-root,
.mantine-InputWrapper-root {
	label {
		margin-bottom: 0;
	}
}

pre {
	&[class*=language-] {
		&.mantine-Prism-code {
			margin: 0;
		}
	}
}

@include media-breakpoint-down(xl) {
	.carousel-cell-sm {
		width: 25%;
	}

	.carousel-cell-md {
		width: 33%;
	}

	.container-fluid {
		.carousel-cell-sm {
			width: 18%;
		}

		.carousel-cell-md {
			width: 25%;
		}
	}
}

@include media-breakpoint-down(md) {
	.carousel-cell-sm {
		width: 33%;
	}

	.carousel-cell-md {
		width: 50%;
	}

	.container-fluid {
		.carousel-cell-sm {
			width: 38%;
		}

		.carousel-cell-md {
			width: 40%;
		}
	}
}

@include media-breakpoint-down(sm) {
	.carousel-cell-sm {
		width: 50%;
	}

	.carousel-cell-md {
		width: 70%;
	}

	.container-fluid {
		.carousel-cell-sm {
			width: 40%;
		}

		.carousel-cell-md {
			width: 40%;
		}
	}
}
