$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Sans Hebrew Thin'),
    local('IBMPlexSansHebrew-Thin'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Thin.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Thin.woff') format('woff');
}

