$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Hebrew Medium'),
    local('IBMPlexSansHebrew-Medium'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Medium.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Medium.woff') format('woff');
}

