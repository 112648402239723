$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Hebrew SemiBold'),
    local('IBMPlexSansHebrew-SemiBold'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-SemiBold.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-SemiBold.woff') format('woff');
}

