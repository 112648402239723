$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Arabic Medium'),
    local('IBMPlexSansArabic-Medium'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Medium.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Medium.woff') format('woff');
}

