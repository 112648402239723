$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 450;
  src: local('IBM Plex Sans Hebrew Text'),
    local('IBMPlexSansHebrew-Text'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Text.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Text.woff') format('woff');
}

