//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

// $primary:      #000;
// $primary-2:     #000;
// $primary-3:     #000;

$border-radius: .1125rem;
$border-radius-lg: .225rem;
$border-radius-sm: .0725rem;

$default-section-padding: $spacer * 1.66666;

$font-family-sans-serif: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base: 1.2rem;

$primary: #6c1b65;
$primary-2: #508484;
$primary-3: #1B1F3B;

$blue: #456990;
$indigo: #541388;
$purple: #6c1b65;
$pink: #dd7373;
$red: #d72638;
$orange: #f46036;
$yellow: #ffc857;
$green: #5fbb97;
$teal: #58b09c;
$cyan: #508484;

$subshell-gold: #bcb563;
$code-color: #426d6d;
$body-color: #090b0c;

$font-family-base: $font-family-sans-serif;

$danger: $red;
$warning: $yellow;
$info: $cyan;

$theme-color-scheme: (
	"primary": $primary,
	"primary-2": $primary-2,
	"primary-3": $primary-3,
	"subshell-gold": $subshell-gold
);

$theme-colors: (
	"primary": $primary,
	"primary-2": $primary-2,
	"primary-3": $primary-3,
	"subshell-gold": $subshell-gold,
	"subshell-gold-doc-search-mark": rgba($subshell-gold, .23)
);

$card-border-radius: 0.225rem;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});



// same as in Leap, but with a different color
$table-accent-bg: rgba(theme-color('subshell-gold'), .05);

// same as in Leap, but with a different color and darker
$table-hover-bg: rgba(theme-color('subshell-gold'), .15);
