$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Devanagari';
  font-style: normal;
  font-weight: 450;
  src: local('IBM Plex Sans Devanagari Text'),
    local('IBMPlexSansDevanagari-Text'),
    url('#{$font-prefix}/IBM-Plex-Sans-Devanagari/fonts/complete/woff2/IBMPlexSansDevanagari-Text.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Devanagari/fonts/complete/woff/IBMPlexSansDevanagari-Text.woff') format('woff');
}

