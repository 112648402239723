$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Sans Thai ExtraLight'),
    local('IBMPlexSansThai-ExtraLight'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai/fonts/complete/woff2/IBMPlexSansThai-ExtraLight.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai/fonts/complete/woff/IBMPlexSansThai-ExtraLight.woff') format('woff');
}

