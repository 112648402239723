$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Arabic SemiBold'),
    local('IBMPlexSansArabic-SemiBold'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-SemiBold.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-SemiBold.woff') format('woff');
}

