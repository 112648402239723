$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Sans Arabic Light'),
    local('IBMPlexSansArabic-Light'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Light.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Light.woff') format('woff');
}

