$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Sans Hebrew ExtraLight'),
    local('IBMPlexSansHebrew-ExtraLight'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-ExtraLight.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-ExtraLight.woff') format('woff');
}

