$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans Thai Looped'),
    local('IBMPlexSansThaiLooped'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Regular.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Regular.woff') format('woff');
}

