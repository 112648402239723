$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Devanagari';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Devanagari Medium'),
    local('IBMPlexSansDevanagari-Medium'),
    url('#{$font-prefix}/IBM-Plex-Sans-Devanagari/fonts/complete/woff2/IBMPlexSansDevanagari-Medium.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Devanagari/fonts/complete/woff/IBMPlexSansDevanagari-Medium.woff') format('woff');
}

