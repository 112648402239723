$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Thai';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Thai SemiBold'),
    local('IBMPlexSansThai-SemiBold'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai/fonts/complete/woff2/IBMPlexSansThai-SemiBold.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Thai/fonts/complete/woff/IBMPlexSansThai-SemiBold.woff') format('woff');
}

