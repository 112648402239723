$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Sans Condensed Thin'),
    local('IBMPlexSansCondensed-Thin'),
    url('#{$font-prefix}/IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Thin.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Thin.woff') format('woff');
}

@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';