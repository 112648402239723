$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans Arabic'),
    local('IBMPlexSansArabic'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Regular.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Regular.woff') format('woff');
}

