$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Arabic';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Sans Arabic Thin'),
    local('IBMPlexSansArabic-Thin'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff2/IBMPlexSansArabic-Thin.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Sans-Arabic/fonts/complete/woff/IBMPlexSansArabic-Thin.woff') format('woff');
}

